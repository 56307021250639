<template>
  <div class="text-[15px]">
    <dl v-if="properties.length">
      <div v-for="property in properties" :key="property.key">
        <dt
          class="inline pr-1 text-[13px] uppercase leading-[1.3] tracking-wide text-gray-700"
        >
          {{ property.key }}:
        </dt>
        <dd class="inline tracking-wide">
          {{ property.value }}
        </dd>
      </div>
    </dl>
    <slot v-else name="empty" />
  </div>
</template>

<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next'
import type { Schemas } from '#shopware'

const props = defineProps<{
  product: Schemas['Product']
}>()

const { t } = useI18n()

const properties = computed<
  {
    key: string
    value: string
  }[]
>(() => {
  const items: Schemas['PropertyGroupOption'][] =
    props.product?.properties ?? []
  const filtered = items
    .filter((property) => property.group.visibleOnProductDetailPage)
    .map((property) => ({
      key: getTranslatedProperty(property.group, 'name'),
      value: getTranslatedProperty(property, 'name'),
    }))

  const grouped = filtered.reduce(
    (acc, property) => {
      const existing = acc.find((item) => item.key === property.key)
      if (existing) {
        existing.value += `, ${property.value}`
      } else {
        acc.push(property)
      }
      return acc
    },
    [] as { key: string; value: string }[]
  )

  if (props.product.extensions?.teaProductExtension) {
    const teaProperties = props.product.extensions.teaProductExtension
    if (teaProperties?.harvestDate) {
      grouped.push({
        key: t('product.properties.harvestDate'),
        value: teaProperties.harvestDate,
      })
    }
    if (teaProperties?.altitudeAboveSeaLevel) {
      grouped.push({
        key: t('product.properties.height'),
        value: t('product.properties.altitudeAboveSeaLevel', {
          height: teaProperties.altitudeAboveSeaLevel,
        }),
      })
    }
  }

  return grouped
})
</script>
